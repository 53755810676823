/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getOrderResume, getCompanyResume } from "../../firebase/orders";
// diseno de todo el componente
import Grid from "@material-ui/core/Grid";
import TopPilots from "./Design/TopPilots";
import LastOrders from "./Design/LastOrders";
import "./Design/styles.css";
// iconos
import BackupTableIcon from "@mui/icons-material/BackupTable";
import EventIcon from "@mui/icons-material/Event";
import Directions from "@material-ui/icons/Directions";
// componentes
import ProgressOrders from "./Design/ProgressOrders";
import GraphicCompany from "./Design/GraphicCompany";
import TopCompany from "./Design/TopCompany";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import Paymethod from "./Design/Paymethod";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "20px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250, // Ancho mínimo
    height: 50, // Altura deseada
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  boldMenuItem: {
    fontWeight: "bold",
  },
}));

const ResumeView = () => {
  const classes = useStyles();
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Inicializar con el mes actual
  const [previousMonth, setPreviousMonth] = useState(new Date().getMonth()); // Inicializar con el mes anterior
  const [today, setToday] = useState(new Date());
  /* datos para guardar las ordenes tanto como del dia como la de los meses */
  const [ordersDay, setOrdersDay] = useState([]);
  const [ordersMonth, setOrdersMonth] = useState([]);
  const [ordersBeforeMonth, setordersBeforeMonth] = useState([]);
  const [orderPreviousDayMonth, setOrderPreviousDayMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // obtenemos todas las companias
  const [companies, setCompanies] = useState([]);
  // compania seleccionada
  const [selectedCompany, setSelectedCompany] = useState("proyectos");

  useEffect(() => {
    let isMounted = true;
    getOrdersDay(isMounted);
    getOrdersMonth(isMounted);
    getOrdersBeforeMonth();
    getOrderDayPrevousMonth();
    // obtener companias
    getCompanies();
    return () => {
      isMounted = false;
    };
  }, []);

  const getCompanies = async () => {
    try {
      const query = await getCompanyResume();
      query.onSnapshot((data) => {
        setCompanies(data.docs.map((doc) => doc.data()));
      });
    } catch (error) {
      console.log(`Error fetching companies: ${error}`);
    }
  };
  const getOrdersDay = async (isMounted) => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    setIsLoading(true);
    try {
      const query = await getOrderResume(startDate, endDate);
      query.onSnapshot((snapshot) => {
        if (isMounted) {
          const allOrders = snapshot.docs.map((orderDoc) => {
            const orderData = orderDoc.data();
            return { id: orderDoc.id, ...orderData };
          });
          setIsLoading(false);
          setOrdersDay(allOrders);
        }
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (isMounted) {
        // Check if the component is still mounted
        setIsLoading(false);
      }
    }
  };

  const getOrdersMonth = async (isMounted) => {
    const startDate = new Date(new Date().getFullYear(), month - 1, 1);
    const endDate = new Date(new Date().getFullYear(), month, 0, 23, 59, 59);

    setIsLoading(true);

    try {
      const query = await getOrderResume(startDate, endDate);
      query.onSnapshot((snapshot) => {
        if (isMounted) {
          const allOrders = snapshot.docs.map((orderDoc) => {
            const orderData = orderDoc.data();
            return { id: orderDoc.id, ...orderData };
          });
          setIsLoading(false);
          setOrdersMonth(allOrders);
        }
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (isMounted) {
        // Check if the component is still mounted
        setIsLoading(false);
      }
    }
  };

  const getOrdersBeforeMonth = async () => {
    const startDate = new Date(new Date().getFullYear(), previousMonth - 1, 1);
    const endDate = new Date(
      new Date().getFullYear(),
      today.getMonth() - 1,
      today.getDate(),
      23,
      59,
      59
    );

    try {
      const query = await getOrderResume(startDate, endDate);
      const allOrders = [];
      query.onSnapshot((snapshot) => {
        snapshot.forEach((orderDoc) => {
          const orderData = orderDoc.data();
          allOrders.push({ id: orderDoc.id, ...orderData });
        });
      });
      setordersBeforeMonth(allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  const getOrderDayPrevousMonth = async () => {
    const previousDayMonth = today.getMonth() - 1;
    const year = today.getFullYear();

    // Asegúrate de manejar el caso especial de enero (mes 0)
    const startDate = new Date(
      year,
      previousDayMonth,
      today.getDate(),
      0,
      0,
      0
    );
    const endDate = new Date(
      year,
      previousDayMonth,
      today.getDate(),
      23,
      59,
      59
    );
    try {
      const query = await getOrderResume(startDate, endDate);
      const snapshot = await query.get();

      const allOrders = snapshot.docs.map((orderDoc) => ({
        id: orderDoc.id,
        ...orderDoc.data(),
      }));

      setOrderPreviousDayMonth(allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  // filtrar por companias

  if (!Array.isArray(companies) || companies.length === 0) {
    console.warn("⚠️ La lista de compañías está vacía o no es un array.");
} else {
    console.log("📋 Lista de compañías cargadas:", companies);
}
// Antes de filtrar, imprimimos las órdenes problemáticas
ordersDay.forEach((order, index) => {
  if (!order.company) {
      console.warn(`⚠️ Orden sin compañía en índice ${index}:`, order);
  } else if (!order.company.name) {
      console.warn(`⚠️ Orden con compañía sin nombre en índice ${index}:`, order);
  }
});

const filteredOrdersByCompany =
selectedCompany === "proyectos"
  ? ordersDay.filter((order) => {
      const company = order.company ? companies.find((c) => c.name === order.company.name) : null;
      if (!company) {
          console.warn(`⚠️ No se encontró la compañía para la orden:`, order);
      }
      return company && company.proyecto !== false;
  })
  : selectedCompany === "delivery"
  ? ordersDay.filter((order) => {
      const company = order.company ? companies.find((c) => c.name === order.company.name) : null;
      if (!company) {
          console.warn(`⚠️ No se encontró la compañía para la orden:`, order);
      }
      return company && company.proyecto === false;
  })
  : selectedCompany === "all"
  ? ordersDay // No aplicar ningún filtro
  : selectedCompany
  ? ordersDay.filter((order) => {
      if (!order.company?.name) {
          console.warn(`⚠️ Orden con compañía sin nombre:`, order);
      }
      return order.company?.name === selectedCompany;
  })
  : ordersDay.filter((order) => {
      const company = order.company ? companies.find((c) => c.name === order.company.name) : null;
      if (!company) {
          console.warn(`⚠️ No se encontró la compañía para la orden:`, order);
      }
      return company && company.proyecto === false;
  });


  // orderPreviousDayMonth
  const filteredOrdersByCompanyPreviousDayMonth =
    selectedCompany === "proyectos"
      ? orderPreviousDayMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto !== false;
        })
      : selectedCompany === "delivery"
      ? orderPreviousDayMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        })
      : selectedCompany === "all"
      ? orderPreviousDayMonth // No aplicar ningún filtro
      : selectedCompany
      ? orderPreviousDayMonth.filter(
          (order) => order.company.name === selectedCompany
        )
      : orderPreviousDayMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        });

  // ordersMonth
  const filteredOrdersByCompanyMonth =
    selectedCompany === "proyectos"
      ? ordersMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto !== false;
        })
      : selectedCompany === "delivery"
      ? ordersMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        })
      : selectedCompany === "all"
      ? ordersMonth // No aplicar ningún filtro
      : selectedCompany
      ? ordersMonth.filter((order) => order.company.name === selectedCompany)
      : ordersMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        });

  // ordersBeforeMonth
  const filteredOrdersByCompanyBeforeMonth =
    selectedCompany === "proyectos"
      ? ordersBeforeMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto !== false;
        })
      : selectedCompany === "delivery"
      ? ordersBeforeMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        })
      : selectedCompany === "all"
      ? ordersBeforeMonth // No aplicar ningún filtro
      : selectedCompany
      ? ordersBeforeMonth.filter(
          (order) => order.company.name === selectedCompany
        )
      : ordersBeforeMonth.filter((order) => {
        const company = order.company?.name
        ? companies.find((c) => c.name === order.company.name)
        : null;
    
          return company && company.proyecto === false;
        });

  // variables para enviar a las tarjetas
  const deliveredOrders = filteredOrdersByCompany.filter(
    (order) => order.status === "Entregado"
  );
  // ordens en ruta
  const onRouteOrders = filteredOrdersByCompany.filter(
    (order) => order.status === "En ruta"
  );
  // ordenes entregadas al  mes
  const deliveredCurrentMonth = filteredOrdersByCompanyMonth.filter(
    (order) => order.status === "Entregado"
  );
  // ordenes entregadas el mes anterior y dia actual
  const deliveredOrderPreviousMonth = filteredOrdersByCompanyPreviousDayMonth.filter(
    (order) => order.status === "Entregado"
  );
  // ordenes entreegadas el mes anterior
  const deliveredOrderLastMonth = filteredOrdersByCompanyBeforeMonth.filter(
    (order) => order.status === "Entregado"
  );

  // ordenamos las compania por orden alfabetico
  const companiesSorted = companies.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  // diferencia de ordenes al dia
  const differenceOrdersDay =
    deliveredOrderPreviousMonth.length !== 0
      ? Math.min(
          ((deliveredOrders.length - deliveredOrderPreviousMonth.length) /
            deliveredOrderPreviousMonth.length) *
            100,
          100
        )
      : 0;

  const differenceOrdersMonth =
    deliveredOrderLastMonth.length !== 0
      ? Math.min(
          ((deliveredCurrentMonth.length - deliveredOrderLastMonth.length) /
            deliveredOrderLastMonth.length) *
            100,
          100
        )
      : 0;

  return (
    <div>
      {!isLoading ? (
        <>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                {"filtrar por compañia".toUpperCase()}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <MenuItem className={classes.boldMenuItem} value="proyectos">
                  {"proyectos".toUpperCase()}
                </MenuItem>
                <MenuItem value="all" className={classes.boldMenuItem}>
                  {"Todas las compañías".toUpperCase()}
                </MenuItem>
                <MenuItem className={classes.boldMenuItem} value="delivery">
                  {"otras".toUpperCase()}
                </MenuItem>
                {companies.map((option) => {
                  if (isEmpty(option.name)) {
                    return null;
                  }
                  return (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={12} md={6} sm={6} lg={4}>
              <ProgressOrders
                difference={differenceOrdersDay}
                title="Ordenes entregadas"
                count={deliveredOrders.length}
                Icon={BackupTableIcon}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={4}>
              <ProgressOrders
                difference={0}
                title={"Ordenes en Ruta"}
                count={onRouteOrders.length}
                Icon={Directions}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={4}>
              <ProgressOrders
                difference={differenceOrdersMonth}
                title="Ordenes del mes"
                count={deliveredCurrentMonth.length}
                Icon={EventIcon}
              />
            </Grid>
          </Grid>

          {/* ultimaas ordenes entregadas */}
          <Grid item xs={12} md={12}>
            <LastOrders ordersDay={deliveredOrders} />
          </Grid>

          {/* grafica para la comparacion */}
          <Grid item xs={12} md={12}>
            <GraphicCompany ordersDay={deliveredOrders} />
          </Grid>

          {/* Tops de los ordenes */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TopPilots ordersDay={deliveredOrders} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Paymethod ordersDay={deliveredOrders} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TopCompany ordersDay={deliveredOrders} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};
export default ResumeView;
